// Include Bootstrap 5
@import "variables";
@import "bootstrap_site";

// Site global
@import "layout";
@import "forms";
@import "header";
@import "footer";

// Pages
@import "about";
@import "article";
@import "contact";
@import "guides_news";
@import "home";
@import "static";


.shadow {
	box-shadow: 0 0 30px rgba(150, 150, 150, 0.33);
}

html.glightbox-open {
  overflow: initial;
}

.gslide-description {
	background-color: $primary !important;

	.gslide-title {
		color: #fff !important;
		text-align: center !important;
		margin: 0 !important;
	}
}

// Breakpoints
$sm: 768px;
$md: 1200px;

// Theme color variables
$primary:    #ff6600;
$secondary:  #2b303c;
$success:    #198754;
$danger:     #dc3545;

// Set default font
$base-font-family: tahoma, verdana, sans-serif;

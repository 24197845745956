.page-about {
	padding: 0;
	margin: 2rem 0 4rem 0;

	.text-panel {
		padding-left: 3rem;

		p {
			font-size: 0.9rem;
			text-align: justify;

			&:last-child {
				margin-bottom: 0;
			}
		}

		ul {
			list-style: none;
			padding: 0;

			li {
				display: flex;
				align-items: center;
				padding: 0;
				margin: 2.1rem 0;

				p {
					display: flex;
					margin-left: 32px;
				}

				.circle {
					background: $primary;
					line-height: 62px;
					display: flex;
					flex-shrink: 0;
					width: 64px;
					height: 64px;
					padding: 0;
					margin: 0;
					border-radius: 32px;
					box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);

					.icon {
						display: inline-block;
						margin: auto;
					}
				}
			}
		}
	}
}

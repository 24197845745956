.btn-block {
	width: 100%;
}

.material {
	border-bottom: 2px solid $primary;

	label:not(.form-check-label) {
		font-weight: 600;
		padding: 0.375rem 0.75rem 0 0.75rem;
	}

	input, select {
		color: #333;
	}

	.form-control {
		background-color: transparent;
		background-repeat: no-repeat;
		background-clip: padding-box;
		background-image: linear-gradient($primary,$primary), linear-gradient(#ddd,#ddd);
		background-size: 0% 2px, 100% 1px;
		background-position: center bottom, center 100%;
		border: 0 none;
		border-radius: 0;
		box-shadow: none;
		transition: background 0s ease-out;

		&:focus {
			background-size: 100% 2px, 100% 1px;
			transition: background 0.3s ease-out;
		}

		&::placeholder {
			color: #999;
		}
	}
}

.form-switch {
	.form-check-input[type="checkbox"] {
		background-color: #7f7f7f;
		border-color: #7f7f7f;
		outline: 0;
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");

		&:checked {
			background-color: $primary;
			border-color: $primary;
			border: none;
			background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
		}
	}
}

form.boxed {
	padding: 20px;
	box-shadow: 0 0 30px rgba(150, 150, 150, 0.33);
}

header {
	background: $secondary;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 997;
	padding: 0.37rem 0;
	border-bottom: 2px solid $primary;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

	a.nav {
		color: #fff;
		font-weight: 600;
		text-decoration: none;
		text-transform: lowercase;
		padding: 0 0.45rem;

		&:hover {
			color: $primary;
		}
	}

	.txt {
		color: $primary;
		font-weight: 800;
		text-decoration: none;
		text-transform: uppercase;
		padding: 0 0.45rem;

		@media (max-width: 520px) {
			display: none;
		}
	}

	.menu {
		display: flex;
		justify-content: space-between;
		align-content: center;
		padding-top: 0.47rem;
		padding-bottom: 0.47rem;
	}

	.logo {
		padding: 0;
		margin: 0;

		img {
			transition: all .12s ease-in-out;
		}

		&:hover img {
			transform: scale(1.49) rotate(333deg);
			transition: all .32s ease-in-out;
		}
	}
}

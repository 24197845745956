.page-guides-news {
	margin-top: 2rem;

	h2.subtitle {
		color: $primary;
		padding: 0.79rem 0;
		margin: 1.27rem 0 0.35rem 0;
	}

	.article {
		margin: 0 0 2rem 0;

		.card {
			height: 100%;
			padding: 0;
			margin: 0;
			border-radius: 0.47rem;
			box-shadow: 0 0 9px 0 rgba(0,0,0,.15);

			a.big {
				font-size: 1.37rem;

				.tag {
					background-color: $primary;
					color: #fff;
					font-size: 1.07rem;
					text-align: center;
					display: block;
					padding: 0.31rem;
					margin: 0;
					border-radius: 0.47rem 0.47rem 0 0;
				}

				img.main {
					max-width: 100%;
				}

				.title {
					font-weight: bold;
					display: block;
					padding: 0.79rem;
				}
			}

			.intro,
			.date {
				padding: 0.27rem 0.77rem;
				margin: 0;
			}
		}
	}
}

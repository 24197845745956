.page-static {
	padding: 4rem 0 0 0;
	margin: 2rem 0 4rem 0;

	p {
		text-align: justify;
	}

	ul {
		padding: 0;
		margin: 0;

		li {
			text-align: justify;
			margin: 0 0 0.69rem 1.7rem;
		}
	}

	ol {
		list-style-type: none;
		counter-reset: item;
		padding: 0;
		margin: 0;

		li {
			text-align: justify;
		}

		& > li {
			display: table;
			counter-increment: item;
			margin-bottom: 0.9rem;

			&:before {
				content: counters(item, ".") ". ";
				display: table-cell;
				padding-right: 0.9rem;
			}
		}

		li ol > li {
			margin: 0 0 0.69rem 0;

			&:before {
				content: counters(item, ".") " ";
			}
		}
	}
}

footer {
	background: $secondary;
	color: #fff;
	font-size: 0.9rem;
	padding: 3rem 0;
	margin: 3rem 0 0 0;

	h4 {
		color: $primary;
		font-size: 1.17rem;
		padding: 0;
		margin: 0 0 0.77rem 0;
	}

	.arrow {
		color: $primary;
	}

	a {
		color: #fff;
		text-decoration: none;

		&:hover {
			color: $primary;
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			padding: 0.3rem 0;
			display: flex;
			align-items: center;
		}

		span {
			font-size: 0.77rem;
			margin-right: 0.7rem;
		}
	}
}

// Back to top button
.back-to-top {
	background: $primary;
	color: #fff;
	text-align: center;
	line-height: 40px;
	width: 40px;
	height: 40px;
	opacity: 0;
	visibility: hidden;
	position: fixed;
	right: 15px;
	bottom: 15px;
	z-index: 996;
	border-radius: 4px;
	transform: rotate(270deg);
	transition: all 0.4s;

	&.active {
		visibility: visible;
		opacity: 1.0;

		&:hover {
			background: #fff;
			color: $primary;
		}
	}

	.arrow {
		font-size: 1.9rem;
		line-height: 0;
	}
}

$article-image-flow: -1.67rem;
$article-image-text-margin: 0.95rem;
$article-image-top-margin: 0.35rem;
$article-image-bottom-margin: 0.35rem;
$article-image-strong-border: 4px;

.page-article {
	margin: 2rem 0 4rem 0;

	h1 {
		.tag {
			background-color: $primary;
			color: #fff;
			font-size: 0.97rem;
			float: right;
			padding: 0.37rem 0.77rem;
			margin: 0 0 0.5rem 0.77rem;
			border-radius: 0.47rem;
		}
	}

	img {
		border: 1px solid #ccc;

		&.left {
			float:left;
			margin: $article-image-top-margin $article-image-text-margin $article-image-bottom-margin $article-image-flow;
			border-left: $article-image-strong-border solid $primary;
		}

		&.main {
			width: calc(100% + #{-2 * $article-image-flow});
			margin: $article-image-top-margin $article-image-flow $article-image-bottom-margin $article-image-flow;
			border-bottom: $article-image-strong-border solid $primary;
		}

		&.right {
			float:right;
			margin: $article-image-top-margin $article-image-flow $article-image-bottom-margin $article-image-text-margin;
			border-right: $article-image-strong-border solid $primary;
		}
	}
}

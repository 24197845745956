.page-contact {
	padding: 0;
	margin: 2rem 0 4rem 0;

	.info-box {
		color: #444;
		text-align: center;
		box-shadow: 0 0 30px rgba(150, 150, 150, 0.33);
		padding: 3rem 0;
		margin-bottom: 2rem;
		border-bottom: 2px solid $primary;

		span {
			color: $primary;
			font-size: 32px;
			text-align: center;
			line-height: 48px;
			display: inline-block;
			width: 70px;
			height: 70px;
			border-radius: 50%;
			padding: 8px;
			border: 2px dotted $primary;
		}

		h3 {
			font-size: 20px;
			color: #777;
			font-weight: 700;
			margin: 10px 0;
		}

		p {
			font-size: 14px;
			text-align: center;
			line-height: 24px;
			padding: 0;
			margin-bottom: 0;
		}
	}
}
